import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Notification } from 'hds-react';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/design-tokens/breakpoints/tokens",
  "title": "Breakpoints tokens - Tokens"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Tokens`}<a parentName="h2" {...{
        "href": "#tokens",
        "aria-label": "tokens permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`PX value`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Padding`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Scaling method`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$breakpoint-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`≥ 320px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$breakpoint-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`≥ 576px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$breakpoint-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`≥ 768px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$breakpoint-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`≥ 992px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--breakpoint-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$breakpoint-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`≥ 1248px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24px, scales`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale padding`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Breakpoint tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <Notification type="alert" label="Container width tokens have changed in HDS 2.0!" className="siteNotification" mdxType="Notification">
  Starting in HDS version 2.0, only the <code>container-width-xl</code> token is used as the maximum width of the content container. Other container width tokens are not used anymore - the content container will take all space available below the <code>content-width-xl</code> token. The rest of the tokens will are now deprecated and will be removed in a future update.
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`PX value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$container-width-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1200px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Container width tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`PX value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$container-width-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`288px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$container-width-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`544px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$container-width-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`720px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--container-width-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$container-width-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`944px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Container width tokens (deprecated)]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      